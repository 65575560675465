import gsap from "gsap"
import { animateTitleEntry, gsapParams, setTitleEntry } from '../../utils/page-animations/common'

const selectors = {
  title: {
    title: '.tpl-clients__title',
    alias: '.tpl-clients__alias',
    line: '.tpl-clients__title-line'
  },
  industries: '.tpl-clients__industries-focus',
  industriesTitle: '.industries-focus__title',
  industriesDesc: '.industries-focus__desc',
  list: '.tpl-clients__list',
  listTitle: '.tpl-clients__list-title',
  listItem: '.mosaic--industries li',
}

const setEntry = (show) => {
  setTitleEntry(
    selectors.title, 
    selectors.title.line.substring(1, selectors.title.line.length),
    show
  )
  setClientListEntry(show)
  setClientIndustryEntry(show)
}

const animateEntry = () => {
  const tl = []
  tl.push(animateTitleEntry(selectors.title))
  tl.push(animateClientIndustryEntry())
  tl.push(animateClientsListEntry())

  return tl
}

const setClientListEntry = (show) => {
  if (!document.querySelector(selectors.list)) {
    return
  }
  
  gsap.set(selectors.listTitle, show ? gsapParams.showInUp : { opacity: 0, translateY: 15 })
  gsap.set(selectors.listItem, show ? gsapParams.showInUp : { ...gsapParams.fadeOutDown30 })
}

const setClientIndustryEntry = (show) => {
  gsap.set(selectors.industriesTitle, show ? gsapParams.show : { ...gsapParams.fadeOut })
  gsap.set(selectors.industriesDesc, show ? gsapParams.show : { ...gsapParams.fadeOut })
}

const animateClientIndustryEntry = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.industries,
      start: 'top 80%',
      once: true
    }
  })

  tl.to(selectors.industriesTitle, { ...gsapParams.fadeIn }, 0)
  tl.to(selectors.industriesDesc, { ...gsapParams.fadeIn }, .15)
  return tl
}

const animateClientsListEntry = () => {
  if (!document.querySelector(selectors.list)) {
    return
  }

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.list,
      start: 'top 70%',
      end: "bottom center",
      once: true
    }
  })
  tl.to(selectors.listTitle, { ...gsapParams.fadeInUp }, 0)
  tl.to(selectors.listItem, { ...gsapParams.fadeInUp, stagger: {
    each: .05,
    grid: 'auto',
    axis: 'both'
  }}, 0)

  return tl
}

export {
  setEntry,
  animateEntry
}