import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ClassicLayout from '../components/Layouts/ClassicLayout'
import Contact from '../components/Contact'
import MosaicIndustriesFocus from '../components/MosaicIndustriesFocus/MosaicIndustriesFocus'
import { graphql } from 'gatsby'
import ListClientsByIndustries from '../components/ListClientsByIndustries'

import { animateEntry, setEntry } from '../utils/page-animations/clients'

import {
  getPageData,
  getPrismicListDocument,
} from '../utils/helpers/transformers'

import ScrollTrigger from 'gsap/ScrollTrigger'
import useAnimationState from '../utils/hooks/useAnimationState'

const Clients = ({ data, uri, pageContext }) => {
  const { routeName, language } = pageContext
  // INTN
  const { t } = useTranslation(['clients'])

  // ANIMATION
  const animationState = useAnimationState({ routeName })

  // DATA
  const $hasRunAnimation = useRef()
  const pageData = useMemo(() => getPageData(data), [data])
  const industries = useMemo(() => {
    const inds = pageData && pageData.industries ? pageData.industries : []
    return inds
      .reduce((list, { industry }) => {
        if (industry.document?.data) {
          const element = { ...industry.document.data }
          element.clients = element.clients
            ? element.clients.sort((a, b) =>
                a.client?.document?.data?.name?.text?.toLowerCase() <
                b.client?.document?.data?.name?.text?.toLowerCase()
                  ? -1
                  : 1
              )
            : []
          list.push(element)
        }
        return list
      }, [])
      .sort((a, b) =>
        a.name.text.toLowerCase() < b.name.text.toLowerCase() ? -1 : 1
      )
  }, [pageData])

  const industriesFocus = useMemo(
    () => getPrismicListDocument(pageData, 'focus_industries', 'industry'),
    [pageData]
  )

  // HOOKS
  useEffect(() => {
    ScrollTrigger.clearScrollMemory()
    setEntry()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setEntry(true)
      return
    }
  }, [animationState.canStart, animationState.isReady])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }

    $hasRunAnimation.current = true
    const tls = animateEntry()

    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [animationState.canPlay])

  return (
    <ClassicLayout
      name={routeName}
      seo={{
        title: t('clients:seo:title'),
        description: t('clients:seo:description'),
      }}
      language={language}
      uri={uri}
      internationalRoute={pageContext.internationalRoute}
    >
      <section className="tpl-clients__hero hide-mobile">
        <div className="container">
          <div className="row">
            <div className="col col-lg-offset-1 col-lg-12 col-md-12">
              <h1
                className="tpl-clients__title ft-no-select h4 ft-500"
                aria-label={t('clients:hero:title')}
              >
                {t('clients:hero:title')}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-clients__industries-focus">
        <div className="container">
          <div className="row">
            <div className="col col-md-3 col-lg-offset-1 col-lg-3 f f-direction-column">
              <h2 className="industries-focus__title ft-400 mb-1">
                {t('clients:industries:title')}
              </h2>
              <p className="industries-focus__desc hide-mobile mb-0 c-alpha-5 max-width-250px">
                {t('clients:industries:subtitle')}
              </p>
            </div>
            <MosaicIndustriesFocus
              className="col col-md-9 col-lg-9"
              industries={industriesFocus}
              animation={animationState}
            />
          </div>
        </div>
      </section>
      <section className="tpl-clients__list">
        <div className="container">
          <div className="row">
            <div className="col col-md-3 col-lg-offset-1 col-lg-3 f f-jc-start">
              <h2 className="mb-4 mb-lg-0 tpl-clients__list-title ft-400">
                {t('clients:list:title')}
              </h2>
            </div>
            <div className="col col-md-9 col-lg-9">
              <ListClientsByIndustries industries={industries} />
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-clients__contact show-only-xl">
        <Contact animation={animationState} />
      </section>
    </ClassicLayout>
  )
}

export default Clients

export const queryClients = graphql`
  query ($language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: allPrismicClientsPage(filter: { lang: { eq: $contentLang } }) {
      ...prismicClientsPage
    }
  }
`
