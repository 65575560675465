import gsap from 'gsap'
import { gsapParams } from '../../utils/page-animations/common'

const selectors = {
  cardIndustry: '.mosaic--industries-focus li',
}

const setMosaicEntry = (show) => {
  gsap.set(
    selectors.cardIndustry,
    show ? gsapParams.showInUp : { ...gsapParams.fadeOutDown30 }
  )
}

const animateMosaicEntry = ({ trigger, start }) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: start,
      end: 'bottom bottom',
      once: true,
    },
  })
  tl.to(selectors.cardIndustry, { ...gsapParams.fadeInUp, stagger: 0.05 }, 0.3)

  return tl
}

export { setMosaicEntry, animateMosaicEntry }
