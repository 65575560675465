import React, { useContext } from 'react'
import LocalizedTransitionLink from '../../LocalizedTransitionLink'
import { internationalRoutes, routeNames } from '../../../utils/helpers/routes'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { PrismicBreakpointPicture } from '../../PrismicPicture'

const CardIndustryFocus = ({
  className,
  img,
  imgSources,
  text,
  uid,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { language } = useContext(I18nextContext)
  const localizedRouteIndustry =
    internationalRoutes[routeNames.industry].langs[language]

  return (
    <LocalizedTransitionLink
      entryTransitionType="slideIn"
      exitTransitionType="none"
      to={`${localizedRouteIndustry}/${uid}`}
      className={`${className || ''} card-industry-focus`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="card">
        <div className="card__thumbnail">
          <PrismicBreakpointPicture
            fallbackImg={img}
            fallbackAlt={`${text} industry illustration`}
            sources={imgSources}
            lazy
          />
        </div>
        <p className="mt-1 mt-md-2 mt-lg-4 mb-0 ft-default-m-small">{text}</p>
      </div>
    </LocalizedTransitionLink>
  )
}
export default CardIndustryFocus
