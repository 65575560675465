import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import CardIndustryFocus from '../Cards/CardIndustryFocus'
import { animateMosaicEntry, setMosaicEntry } from './animation'

const MosaicIndustriesFocus = ({ className, industries, animation }) => {
  const [hoveredCardId, setHoveredCardId] = useState(null)
  const handleMouseEnter = useCallback(
    (cardUid) => () => setHoveredCardId(cardUid),
    []
  )
  const handleMouseLeave = useCallback(() => setHoveredCardId(null), [])
  const $hasRunAnimation = useRef(false)

  useEffect(() => {
    setMosaicEntry()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animation.canStart && animation.isReady) {
      $hasRunAnimation.current = true
      setMosaicEntry(true)
      return
    }
  }, [animation.canStart, animation.isReady])

  useEffect(() => {
    if (!animation.canPlay || $hasRunAnimation.current) {
      return
    }

    $hasRunAnimation.current = true
    const tl = animateMosaicEntry({
      trigger: '.tpl-clients__industries-focus',
      start: 'top 80%',
    })
    return () => {
      tl && tl.kill()
    }
  }, [animation.canPlay])

  return (
    <ul
      className={`${
        className || ''
      } mosaic mosaic--2 mosaic-lg-2 mosaic--industries-focus`}
    >
      {industries.map((industryFocus) => {
        if (!industryFocus.data.has_page) return null
        const uid = industryFocus.uid
        const className =
          hoveredCardId && hoveredCardId !== uid ? 'c-alpha-4' : ''

        const imgSources = {
          all: industryFocus.data.thumbnail_all,
          md: industryFocus.data.thumbnail_md,
          lg: industryFocus.data.thumbnail_lg,
        }

        return (
          <li key={uid}>
            <CardIndustryFocus
              className={className}
              uid={uid}
              text={industryFocus.data.name.text}
              img={industryFocus.data.thumbnail}
              imgSources={imgSources}
              onMouseEnter={handleMouseEnter(uid)}
              onMouseLeave={handleMouseLeave}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default memo(MosaicIndustriesFocus)
