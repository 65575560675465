import React, { memo, useCallback, useContext, useState } from 'react'
import { internationalRoutes, routeNames } from '../../utils/helpers/routes'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { addTrailingSlash } from '../../utils/helpers/utils'

const ListClientsByIndustries = ({ industries }) => {
  const { language } = useContext(I18nextContext)
  const localizedRouteProjects =
    internationalRoutes[routeNames.projects].langs[language]
  const [hoveredIndustryId, setHoveredIndustryId] = useState(null)
  const handleMouseEnter = useCallback(
    (industryId) => () => setHoveredIndustryId(industryId),
    []
  )
  const handleMouseLeave = useCallback(
    (industryId) => () => {
      if (industryId === hoveredIndustryId) {
        setHoveredIndustryId(null)
      }
    },
    [hoveredIndustryId]
  )

  return (
    <ul className="mosaic mosaic-lg-3 mosaic--industries">
      {industries.map((industry, i) => {
        const industryName = industry.name.text || '-'
        const industryId = `${industryName}${i}`
        const isHovering = hoveredIndustryId != null

        const isHoveringClassName =
          isHovering && hoveredIndustryId === industryId ? 'is-active' : ''
        const isUnhoveringClassName =
          isHovering && hoveredIndustryId !== industryId ? 'is-unactive' : ''
        const liClassName = `${isHoveringClassName} ${isUnhoveringClassName}`

        return (
          <li key={i} role="presentation" className={liClassName}>
            <div>
              <p className="ft-default-m-small mb-0 mb-lg-1">{industryName}</p>
              {industry.clients.map((client, index) => {
                const clientName = client.client.document?.data.name.text || '-'
                return (
                  <LocalizedTransitionLink
                    key={index}
                    onMouseEnter={handleMouseEnter(industryId)}
                    onMouseLeave={handleMouseLeave(industryId)}
                    to={`${addTrailingSlash(localizedRouteProjects)}?s=${encodeURIComponent(
                      clientName
                    )}`}
                    addTrailingSlash={false}
                    className="u-block ft-default-m-small pt-md-3 pt-2 mb-0 c-gray-600"
                  >
                    <span>{clientName}</span>
                  </LocalizedTransitionLink>
                )
              })}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ListClientsByIndustries)
